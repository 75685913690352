import { ContentItem } from '@kentico/kontent-delivery';
import get from 'lodash/get';
import { Models } from '.';
import { IFormComponentProps } from '../components/form/contact-form/contact-form-types';

export interface IContactFormProps {
  contactPersonTitle: string;
  pageHeader: string;
  form: IFormComponentProps;
}

export class ContactPageModel extends ContentItem {
  get contactPersonTitle(): string {
    return get(this._config, 'elements.contact_person_title.value', '');
  }

  get pageHeader(): string {
    return get(this._config, 'elements.page_header.value', '');
  }

  get form(): IFormComponentProps {
    const getForm = get(this._config, 'elements.form.value[0]', null);

    const transformForm = getForm
      ? Models.FormComponentModel.create(getForm as IFormComponent)
      : null;

    return transformForm;
  }

  public static create(data: IContactForm): IContactFormProps {
    const { contactPersonTitle, pageHeader, form } = new ContactPageModel(data);
    return {
      contactPersonTitle,
      pageHeader,
      form
    };
  }
}
