import { ContentItem } from '@kentico/kontent-delivery';
import get from 'lodash/get';
import { Models } from '.';
import { IEmployeeBoxProps } from '../components/employee-box/employee-box';

export interface IContactPersonProps {
  person: IEmployeeBoxProps | null;
  country: string;
  countryCode: string;
}

export class ContactPersonModel extends ContentItem {
  get person(): IEmployeeBoxProps | null {
    const getPerson = get(this._config, 'elements.person_data.value[0]', null);
    const transformPerson = getPerson
      ? Models.EmployeeBoxModel.create(getPerson as IEmployeeBox)
      : null;

    return transformPerson;
  }

  get country(): string {
    return get(this._config, 'elements.country.value', '');
  }

  get countryCode(): string {
    return get(this._config, 'elements.country_code.value', '');
  }

  public static create(data: IContactPerson): IContactPersonProps {
    const { person, country, countryCode } = new ContactPersonModel(data);
    return {
      person,
      country,
      countryCode
    };
  }
}
